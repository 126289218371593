.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    flex: 1;
}

.intro-paragraph {
    width: 100%;          /* Set the width */
    height: 100%;         /* Set the height to make it square */
    font-size: 16px;
    display: flex;         /* Use flexbox to center the text both vertically and horizontally */
    align-items: center;   /* Center the text vertically */
    justify-content: center; /* Center the text horizontally */
    word-wrap: break-word;    /* To ensure long words or links don't overflow */
    overflow: auto;          /* In case content is more, it will scroll */
    margin-top: 3%;
    margin-bottom: 3%;
}

.section-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px;
}

.section-links a {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 2%;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    border: 1px solid black;
    border-radius: 8px;
    transition: background-color 0.3s;
    
    padding: 13px 20px; /* Added padding here. Adjust as needed */
}

.section-links a:hover {
    color: #4713f3;
    background-color: #e7e7ec;
    font-size: 15px;
    transition: color 0.5s ease;
}

.section-links-2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.section-links-2 a {
    font-size: 12px;
    font-weight: bold;
    margin-top: 100%;
    margin-bottom: 0%;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    transition: background-color 0.3s;
    border-style: dashed;
    border-radius: 8px;
    border-width: 0.5px;
    
    padding: 13px 25px; /* Added padding here. Adjust as needed */
}

.section-links-2 a:hover {
    color: #4713f3;
    background-color: #e7e7ec;
    font-size: 12px;
    transition: color 0.5s ease;
}


.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Take up full viewport height */
}

/* Mobile responsiveness */
@media (max-width: 768px) {


    .intro-paragraph {
        width: 100%;          /* Set the width */
        height: 100%;         /* Set the height to make it square */
        font-size: 12px;
        display: flex;         /* Use flexbox to center the text both vertically and horizontally */
        align-items: center;   /* Center the text vertically */
        justify-content: center; /* Center the text horizontally */
        word-wrap: break-word;    /* To ensure long words or links don't overflow */
        overflow: auto;          /* In case content is more, it will scroll */
        margin-top: 10%;
        margin-bottom:14%
    }
    
    .section-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0px;
        font-size: 10px;
    }
    
    .main-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1px;
        flex: 1;
    }

    .section-links a {
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 1%;
        color: #333;
        text-decoration: none;
        transition: color 0.3s ease;
        border: 1px solid black;
        border-radius: 8px;
        transition: background-color 0.3s;
        
        padding: 10px 10px; /* Added padding here. Adjust as needed */
    }

    .section-links {
        gap: 5px;
    }

    .section-links a {
        font-size: 10px;
    }

    .section-links-2 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    
    .section-links-2 a {
        font-size: 10px;
        font-weight: bold;
        margin-top: 30%;
        margin-bottom: 0%;
        color: #333;
        text-decoration: none;
        transition: color 0.3s ease;
        transition: background-color 0.3s;
        border-style: dashed;
        border-radius: 8px;
        border-width: 0.5px;
        
        padding: 5px 15px; /* Added padding here. Adjust as needed */
    }
    
    .section-links-2 a:hover {
        color: #4713f3;
        background-color: #e7e7ec;
        font-size: 10px;
        transition: color 0.5s ease;
    }
}