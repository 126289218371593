.footer {
    padding: 1% 0;
    background-color: #ffffff; 
    text-align: center;
}

.footer i {
    margin-bottom: 10px;  /* Adjust as needed */
}

.footer .fa-globe {
    color: goldenrod;
}

.footer a:hover .fa-globe {
    color: #4713f3;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .footer {
        padding: 5% 0;
        background-color: #ffffff; 
        text-align: center;
    }

}