.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    margin-top: 50px;
}

.logo {
    max-width: 200px; 
    height: auto;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-top: 20%;
    }

    .logo {
        max-width: 150px; 
        height: auto;
    }

}