/* General Container Styles */
.subsubsection-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 100vh; 
}

/* Container for the Side Navigation */
.side-nav-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 10%;
    width: 220px;
    display: flex;
    align-items: center; 
}

/* Side Navigation Styles */
.subsubsection-side-nav {
    position: sticky;
    top: 50%;
    transform: translateY(-50%); 
    padding: 15px;
    list-style-type: none;
    width: 200px;
}

.subsubsection-side-nav a {
    display: block;
    padding: 8px 16px;
    margin: 8px 0;
    text-decoration: none;
    color: black;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.subsubsection-side-nav a:hover {
    background-color: #e7e7ec;
    color: #4713f3;
}

/* Main Content Styles */
.subsubsection-content {
    flex: 1;
    padding: 15px;
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

/* Replacing section-intro-paragraph */
.subsubsection-paragraph {
    text-align: left;
    font-size: 19px;
    margin-top: 15px;
    max-width: 660px;
}

/* Navigation (arrows at the bottom) styles */
.subsubsection-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 70px;
    gap: 20px; 
}

.subsubsection-navigation a {
    padding: 8px 16px;
    text-decoration: none;
    color: black;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.subsubsection-navigation a:hover {
    background-color: #e7e7ec;
    color: #4713f3;
}

.titles {
    text-align: left !important;
    width: 100%;
}

.codeStyle {
    white-space: pre-wrap;      
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap;      
    white-space: -o-pre-wrap;    
    word-wrap: break-word;       
    max-width: 650px;
  }

  .codeStyle_small {
    white-space: pre-wrap;      
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap;      
    white-space: -o-pre-wrap;    
    word-wrap: break-word;       
    max-width: 650px;
    font-size: 0.9em;
}

.codeStyle_tiny {
    white-space: pre-wrap;      
    white-space: -moz-pre-wrap;  
    white-space: -pre-wrap;      
    white-space: -o-pre-wrap;    
    word-wrap: break-word;       
    max-width: 650px;
    font-size: 0.3em;
}

.section-links-astro {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top:3%;
}

.section-links-astro a {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: -25%;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    border-radius: 8px;
    transition: background-color 0.3s;
    
    padding: 10px 5px; /* Added padding here. Adjust as needed */
}

.section-links-astro a:hover {
    color: red;;
    background-color: #e7e7ec;
    font-size: 20px;
    transition: color 0.5s ease;
}

/* Container for the Side Navigation */
.side-nav-container-astro {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 10%;
    width: 220px;
    display: flex;
    align-items: center; 
}

/* Side Navigation Styles */
.subsubsection-side-nav-astro {
    position: sticky;
    top: 50%;
    transform: translateY(-50%); 
    padding: 15px;
    list-style-type: none;
    width: 200px;
}

.subsubsection-side-nav-astro a {
    display: block;
    padding: 8px 16px;
    margin: 8px 0;
    text-decoration: none;
    color: black;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.subsubsection-side-nav-astro a:hover {
    background-color: #e7e7ec;
    color: red;
}


/* Images */
.flex-container {
    display: flex;
    justify-content: center; 
    align-items: center;
  }

  .flex-container-caption {
    display: flex;
    flex-direction: column; /* This aligns children (image and caption) in a column */
    align-items: center; /* This centers the items horizontally */
  }

  .image-medium {
    width: 90%;
    margin: 0 auto;
    height: auto;
  }

  figcaption {
    font-size: 12pt;
  }

.image-small {
    width: 50%;
    margin: 0 auto;
    height: auto;
  }

  .image-tiny {
    width: 30%;
    margin: 0 auto;
    height: auto;
  }

  /* Math */

  .math-container {
    display: flex;
    flex-direction: column;
  }
  
  .math-container .BlockMath {
    width: 90%; 
  }

/* Big Math Adjustment */
.custom-math-size {
    font-size: 12px;
}

.katex-display {
    font-size: 17px; /* Adjust the size as needed */
}

strong {
    font-size: smaller; /* or you can use specific units like px, em, etc. */
}

b {
    font-size: smaller; /* or you can use specific units like px, em, etc. */
}

h4 {
    font-size: 1.2em; /* Adjust the size as needed */
}

.centered-table {
    margin-left: auto;
    margin-right: auto;
    width: 60%; /* or any percentage or fixed width you prefer */
    border-collapse: collapse;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  th, td {
    border: 1px solid #dddddd;
    padding: 8px; /* or any spacing you prefer */
  }
  th {
    background-color: #f2f2f2;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  tr:hover {
    background-color: #e8e8e8;
  }
  

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .subsubsection-container {
        flex-direction: column;
    }

    /* Big Math Adjustment */
    .custom-math-size {
        font-size: 10px;
    }

    .katex-display {
        font-size: 11px; /* Adjust the size as needed */
    }

    .code-cleaned {
        max-width: 100%;
    }

    strong {
        font-size: smaller; /* or you can use specific units like px, em, etc. */
    }

    /* Main Content Styles */
    .subsubsection-content {
        flex: 1;
        padding: 15px;
        margin-top: 5%;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        max-width: 90;
        gap: 20px;
    }

    .small_table {
        font-size: 0.6em;
    }

    /* Replacing section-intro-paragraph */
    .subsubsection-paragraph {
        text-align: left;
        font-size: 14px; /* Adjust the font size if necessary */
        margin-top: 15px;
        padding: 0 0px; /* Adjust padding to ensure text does not touch the edges */
        overflow-wrap: break-word; /* Ensure long words do not cause overflow */
        max-width: 100;
    }

    .subsubsection-navigation {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font-size: 12px;
        gap: 20px; /* adds space between the arrow links */
    }

    .side-nav-container {
        display: none;
    }

    .side-nav-container-astro {
        display: none;
    }

    .codeStyle_small {
        white-space: pre-wrap;
        overflow-x: auto; /* Allow horizontal scrolling on overflow */
        font-size: 0.8em; /* Adjust font size as needed */
        box-sizing: border-box; /* Padding and borders are included in width */
    }

    .codeStyle_tiny {
        white-space: pre-wrap;
        overflow-x: auto; /* Allow horizontal scrolling on overflow */
        font-size: 0.6em; /* Adjust font size as needed */
        width: 100%; /* Full width */
        box-sizing: border-box; /* Padding and borders are included in width */
    }

    .SyntaxHighlighter {
        width: 100%; /* Ensure it takes up to 100% of its parent width */
        max-width: 100%; /* Prevent it from exceeding the viewport width */
        overflow-x: auto; /* Enable horizontal scrolling only when necessary */
        padding: 0; /* Adjust padding if necessary */
        box-sizing: border-box; /* Padding and borders are included in width */
    }
    
    .math-container {
        display: flex;
        flex-direction: column;
        font-size: 12px;
      }
      
      .math-container .BlockMath {
        width: 90%; 
      }
}

@media (max-width: 1360px) {
    .side-nav-container {
        display: none;
    }

    .side-nav-container-astro {
        display: none;
    }
}