.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; 
}

.section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    flex: 1;
}

.section-intro-paragraph {
    width: 400px;          /* Set the width */
    height: 130px;         /* Set the height to make it square */
    font-size: 16px;
    display: flex;         /* Use flexbox to center the text both vertically and horizontally */
    align-items: center;   /* Center the text vertically */
    justify-content: center; /* Center the text horizontally */
    word-wrap: break-word;    /* To ensure long words or links don't overflow */
    overflow: auto;          /* In case content is more, it will scroll */
}

.subsection-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0px;
}

.subsection-links a {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2%;
    color: #333;
    text-decoration: none;
    transition: color 0.3s ease;
    border: 1px solid black;
    border-radius: 8px;
    transition: background-color 0.3s;
    
    padding: 15px 30px; /* Added padding here. Adjust as needed */
}

.subsection-links a:hover {
    color: #4713f3;
    background-color: #e7e7ec;
    font-size: 14px;
    transition: color 0.5s ease;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .section-intro-paragraph {
        width: 100%;          /* Set the width */
        height: 100%;         /* Set the height to make it square */
        font-size: 12px;
        display: flex;         /* Use flexbox to center the text both vertically and horizontally */
        align-items: center;   /* Center the text vertically */
        justify-content: center; /* Center the text horizontally */
        word-wrap: break-word;    /* To ensure long words or links don't overflow */
        overflow: auto;          /* In case content is more, it will scroll */
        margin-top: 40%;
        margin-bottom: 20%;
    }
    
    .subsection-links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 0px;
        font-size: 10px;
    }
    
    .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 1px;
        flex: 1;
    }

    .subsection-links a {
        font-size: 10px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #333;
        text-decoration: none;
        transition: color 0.3s ease;
        border: 1px solid black;
        border-radius: 8px;
        transition: background-color 0.3s;
        
        padding: 10px 15px; /* Added padding here. Adjust as needed */
    }

    .subsection-links {
        gap: 5px;
        padding-left: 7%;
        padding-right: 7%;
    }

    .subsection-links a {
        font-size: 12px;
    }
}